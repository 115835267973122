<template>
    <div>
        <Toolbar
            ref="toolbar"
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
        />
        <!-- 编辑器 -->
        <Editor
            class="editor"
            :defaultConfig="editorConfig"
            v-model="html"
            @onCreated="editorCreated"
        />
    </div>

</template>

<script>

// 引入 wangEditor
import '@wangeditor/editor/dist/css/style.css';
import wangEditor from 'wangeditor'
import {DomEditor} from '@wangeditor/editor'
import storage from "@/utils/storage";
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";

export default {
    components: {Toolbar, Editor},
    data() {
        this.editor = null;
        return {
            html: '',
            toolbarConfig: {
                toolbarKeys: ["headerSelect", 'uploadImage', "color", "bgColor", "|", "fontSize", "lineHeight", "|", {
                    "key": "group-justify",
                    "title": "对齐",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
                    "menuKeys": ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"]
                }, {
                    "key": "group-indent",
                    "title": "缩进",
                    "iconSvg": "<svg viewBox=\"0 0 1024 1024\"><path d=\"M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z\"></path></svg>",
                    "menuKeys": ["indent", "delIndent"]
                }, "|", "emotion", "insertLink",
                    "insertTable", "codeBlock", "divider"]
            },
            editorConfig: {
                placeholder: '留下您的精彩解答...',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: async (file, insertFn) => {
                            let formData = new FormData();
                            formData.append('image', file);
                            let data = await this.$axiosUpload.post('', formData).catch(() => {

                            });
                            if (!data) {
                                this.$message.warning('图片上传失败!');
                                return;
                            }
                            insertFn(data.data.src);
                        }
                    }
                }
            },
            // editorData:'',
        }
    },
    props: ['content', 'config'],
    watch: {
        content(v) {
            console.log(v);
            this.html = v;
        },
    },
    methods: {
        editorCreated(editor) {
            this.editor = Object.seal(editor);
            this.html=this.content;
        },
        getHtml() {
            // 通过代码获取编辑器内容
            return this.html;
        }
    },
    beforeDestroy() {
        // 调用销毁 API 对当前编辑器实例进行销毁
        this.editor.destroy()
        this.editor = null
    }
}
</script>
<style lang="less">
    .editor{
        overflow-y: hidden;
        height: 180px;
    }
</style>
