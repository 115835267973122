import storage from "@/utils/storage";
import store from '@/vuex';

function loop(data, id = 'id', pid = 'pid', start_id = '0') {
    let tree = [];
    for (let v of data) {
        if (v[pid] + '' === start_id + '') {
            v['children'] = loop(data, id, pid, v[id]);
            tree.push(v);
        }
    }
    return tree;
}

function toOption(data, key, label) {
    let options = [];
    for (let v of data) {
        options.push({key: v[key], label: v[label]});
    }
    return options;
}

export const reStoreKeyValue = function (key, value) {
    let readableValue = value;
    // debugger;
    if (store.state.dict[key]) {
        store.state.dict[key].map(v => {
            if (v.value === value) readableValue = v.label;
        });
    }
    return readableValue;
}

export const formatTime = function (time, format) {
    try {
        if (!time) return '';
        let t = time.toString().length === 10 ? time * 1000 : time;
        t = new Date(t);
        let f = {
            Y: t.getFullYear(),
            m: (t.getMonth() + 1).toString().padStart(2, '0'),
            d: t.getDate().toString().padStart(2, '0'),
            H: t.getHours().toString().padStart(2, '0'),
            i: t.getMinutes().toString().padStart(2, '0'),
            s: t.getSeconds().toString().padStart(2, '0')
        }
        if (!format) format = 'Y-m-d';
        let result = format;
        for (let k in f) {
            result = result.replace(k, f[k]);
        }
        return result;
    } catch (e) {
        // console.log(e);
        return '';
    }
}

function checkPermission(check) {
    let permission = storage.permission();
    let userData = storage.userData();
    if (!userData) return false;
    if (userData.group_id === 1) return true;
    if (!permission) return false;
    for (let k of permission) {
        if (Array.isArray(check.method)) {
            if (k.url.toLowerCase() === check.url.toLowerCase() && check.method.indexOf(k.type.toLowerCase()) > -1) return true;
        } else {
            if (k.url.toLowerCase() === check.url.toLowerCase() && check.method.toLowerCase() === k.type.toLowerCase()) return true;
        }
    }
    return false;
}

function formatFileSize(size) {
    size = parseFloat(size);
    if (isNaN(size)) return '';
    let unit = ['b', 'Kb', 'M', 'GB'];
    let index = 0;
    while (size > 1024 && unit.length - 1 > index) {
        size = size / 1024;
        index++;
    }
    return size.toFixed(1) + unit[index];
}

function getJumpUrl(url) {
    let all = storage.permission();
    let jumpUrl = '';
    for (let item of all) {
        if (item.type !== 'menu') continue;
        !jumpUrl ? jumpUrl = item.url : true;
        if (item.url === url) {
            return url;
        }
    }
    return jumpUrl;
}

export const stripHtmlTag = function (str) {
    return str.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, "");
}

function checkLogin() {
    if (storage.token()) {
        return true;
    }
    store.state.need_login = Math.random();
    return false;
}


export const checkPhone = (rule, value, callback) => {
    if (!value) {
        return callback(new Error('手机号不能为空'));
    } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        // console.log(reg.test(value));
        if (reg.test(value)) {
            callback();
        } else {
            return callback(new Error('请输入正确的手机号'));
        }
    }
};

/**
 * 从html代码中匹配返回图片标签img的属性src的值的集合
 * @param htmlData
 * @return Array
 */
export const findAllImgSrcsFromHtml = function (htmlData) {
    let imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签
    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; // 匹配图片中的src
    let arr = htmlData.match(imgReg); //筛选出所有的img
    if (!arr || (Array.isArray(arr) && !arr.length)) {
        return false;
    }
    let srcArr = [];
    for (let i = 0; i < arr.length; i++) {
        let src = arr[i].match(srcReg);
        // 获取图片地址
        srcArr.push(src[1]);
    }
    return srcArr;
}

/**
 * 从rtf内容中匹配返回图片数据的集合
 * @param rtfData
 * @return Array
 */
export const extractImageDataFromRtf = function (rtfData) {
    if (!rtfData) {
        return [];
    }
    const regexPictureHeader = /{\\pict[\s\S]+?({\\\*\\blipuid\s?[\da-fA-F]+)[\s}]*/
    const regexPicture = new RegExp('(?:(' + regexPictureHeader.source + '))([\\da-fA-F\\s]+)\\}', 'g');
    const images = rtfData.match(regexPicture);
    const result = [];
    if (images) {
        for (const image of images) {
            let imageType = false;
            if (image.includes('\\pngblip')) {
                imageType = 'image/png';
            } else if (image.includes('\\jpegblip')) {
                imageType = 'image/jpeg';
            }
            if (imageType) {
                result.push({
                    hex: image.replace(regexPictureHeader, '').replace(/[^\da-fA-F]/g, ''),
                    type: imageType
                });
            }
        }
    }
    return result;
}

/**
 * 将html内容中img标签的属性值替换
 * @param htmlData html内容
 * @param imageSrcs html中img的属性src的值的集合
 * @param imagesHexSources rtf中图片数据的集合，与html内容中的img标签对应
 * @param isBase64Data 是否是Base64的图片数据
 * @return String
 */
export const replaceImagesFileSourceWithInlineRepresentation =function (htmlData, imageSrcs, imagesHexSources, isBase64Data = true)
{
    if (imageSrcs.length === imagesHexSources.length) {
        for (let i = 0; i < imageSrcs.length; i++) {
            const newSrc = isBase64Data ?
                `data:${imagesHexSources[i].type};base64,${_convertHexToBase64(imagesHexSources[i].hex)}` :
                imagesHexSources[i];
            htmlData = htmlData.replace(imageSrcs[i], newSrc);
        }
    }
    return htmlData;
}
/**
 * 十六进制转base64
 */
export const _convertHexToBase64 = function (hexString) {
    return btoa(hexString.match(/\w{2}/g).map(char => {
        return String.fromCharCode(parseInt(char, 16));
    }).join(''));
}
export default {
    loop,
    stripHtmlTag,
    checkLogin,
    toOption,
    reStoreKeyValue,
    checkPermission,
    getJumpUrl,
    formatFileSize
};
