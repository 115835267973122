<template>
    <svg class="svg-icon" :style="{marginRight: marginRight}" aria-hidden="true">
        <use :xlink:href="iconName"></use>
    </svg>
</template>

<script>
    import '@/assets/iconfont/iconfont';
    export default {
        name: 'icon-svg',
        props: {
            iconClass: {
                type: String,
                required: true
            },
            marginRight:{
                type:String,
                default:'10px'
            }
        },
        computed: {
            iconName() {
                return `#icon-${this.iconClass}`
            }
        }
    }
</script>

<style >
    .svg-icon {
        width: 1em;
        height: 1em;
        vertical-align: 0;
        //margin-right: 10px;
        fill: currentColor;
        overflow: hidden;
    }
</style>
