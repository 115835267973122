import axios from 'axios';
import app from '@/main';
import store from '@/vuex/index';
import {Message} from "element-ui";
import storage from "@/utils/storage";

let elseConfig = {
    need: {
        error: false,
        loading: true,
        progress: true,
    },
    default:{
        error: false,
        loading: true,
        progress: true,
    }
};

export default (baseUrl, config) => {
    let baseConfig = Object.assign({}, {
        baseURL: baseUrl,
        timeout: 8000,
        responseType: process.env.NODE_ENV === 'development' ? 'json' : '',
    }, config || {});
    let request = axios.create(baseConfig);
    request.interceptors.request.use(config => {
        //每次先还原数据
        elseConfig.need=JSON.parse(JSON.stringify(elseConfig.default));
        if (config.need){
            for (let k in config.need){
                if (typeof config.need[k]==='boolean'){
                    elseConfig.need[k]=config.need[k];
                }
            }
        }
        if (elseConfig.need.progress){
            app.$Progress.start();
        }
        if (elseConfig.need.loading){
            store.dispatch('loading', true);
        }
        //增加token的内容
        config.headers.auth = storage.token() || '';
        if (config.url.indexOf('/bbs/') === 0 || config.url.indexOf('/app/') === 0||config.url.indexOf('/bigmap/') === 0) {
            config.url += '.bb';
        }
        if (config.baseURL===process.env.VUE_APP_UPLOAD_HOST){
            config.headers.upload = storage.storage(process.env.VUE_APP_UPLOAD_CODE) || '';
        }
        if (config.method === 'get') {
            if (!config.params) config.params = {_t: Math.random()};
            config.params._t = Math.random();
        }
        // if (config.method==='delete'){
        //     console.log(config);
        // }
        if (config.baseURL === process.env.VUE_APP_HISTORY_HOST) {
            config.headers.bigemap = '1';
        }
        return config;
    }, error => {
        app.$Progress.fail();
        store.dispatch('loading', false);
        Message.error('请求出错了');
        return Promise.reject(error);
    });

    request.interceptors.response.use(data => {
        if (elseConfig.need.progress){
            app.$Progress.finish();
        }
        store.dispatch('loading', false);
        if (!data.data || data.data.code !== 200) {
            let code = data.data && data.data.code;
            // console.log('222');
            // console.log(data.data);
            switch (code) {
                case 4001:
                    storage.clear();
                    // app.$router.push({path: '/login'});
                    break;
            }
            //系统中的很多地方不需要有错误提示，这里全局禁用了，只有在需要的时候才会手动展示
            if (elseConfig.need.error){
                Message.error(data.data && (data.data.message || data.data.msg) || '遇到未知错误!');
            }
            return Promise.reject(data.data && (data.data.message || data.data.msg) || '遇到未知错误!');
        }
        return data.data;
    }, (error) => {
        if (elseConfig.need.progress){
            app.$Progress.fail();
        }
        store.dispatch('loading', false);
        // Message.error('服务器在打盹，请稍后重试!');
        return Promise.reject(error)
    });
    return request;
};
