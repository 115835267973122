<template>
    <div>
        <el-link @click="pre" v-if="imgList" type="primary">
            {{ text || '查看图片' }}
        </el-link>
        <span v-else>无</span>
    </div>
</template>

<script>
export default {
    name: "image-preview",
    props: ['images', 'text'],
    watch: {},
    computed: {
        imgList: function () {
            if (!this.images || !Array.isArray(this.images) || !this.images.length) return false;
            return true;
        }
    },
    methods: {
        pre() {
            let img = [];
            this.images.map(v => {
                img.push(v.url);
            });
            if (!img.length) return;
            if (img.length === 1) {
                this.$hevueImgPreview(img[0]);
            } else {
                this.$hevueImgPreview({
                    multiple: true, // 开启多图预览模式
                    nowImgIndex: 0, // 多图预览，默认展示第二张图片
                    imgList: img, // 需要预览的多图数组
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
