<template>
    <div class="file-control">
        <div v-show="title!==false" class="title">{{ title }}</div>
        <div class="file-list">
            <span class="item" :class="{thumb}" v-for="(item,index) in myFile" :key="item.path">
            <span v-if="thumb" @click="downloadFile(item)" class="img-span">
                <img v-if="['png','jpg','gif','jpeg','png'].indexOf(item.extension)>-1" :src="item.src||item.url" alt="">
                <img v-else src="/static/manage/images/chart/file.png" alt="">
            </span>
                <a class="title-text" @click="downloadFile(item)"   v-else>
                    {{item.title}}
                </a>
<!--                                            <span @click="downloadFile(item)">{{ item.title||'文件' }}</span>-->
            <span v-if="editable" @click="remove(index)" class="remove">
                <i class="el-icon-delete"></i>
            </span>
        </span>
            <span v-if="editable &&file.length<limit" class="upload">
            <input ref="file" @change="change" style="position: absolute;top:-9999px;" type="file" :accept="accept">
            <a @click="upload" href="javascript:void (0)"><i class="el-icon-upload"></i><span
                class="ml-1">{{ addTitle }}</span></a>
        </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {

        },
        limit: {
            type: Number,
            default: 9999
        },
        action: {
            type: String,
            default: '/bbs/upload/file',
        },
        accept: {
            type: String,
            default: '.doc,.docx,.rar,.zip,.xls,.xlsx',
        },
        addTitle: {
            type: String,
            default: '添加',
        },
        editable: {
            type: Boolean,
            default: true,
        },
        thumb:{
          type:Boolean,
            default:true
        },
        file: {
            type: Array,
            default: function () {
                return []
            }
        },
    },
    name: "file",
    data() {
        return {

        }
    },
    computed:{
       myFile:function (){
           return this.file.map(v => {
               let url=v.url||v.src;
               if (url){
                   v.extension=url.split('.').pop().toLowerCase();
               }
               return v;
           });
       }
    },
    methods: {
        remove(index) {
            this.$confirm('你真的要删除此项吗?', '操作确认').then(() => {
                this.file.splice(index, 1);
            });
        },
        downloadFile(item) {
            let a = document.createElement('a');
            a.target = '_blank';
            a.download = 'download.'+item.extension;
            // a.download = 'ddd.zip';
            a.href = item.url||item.src;
            a.click();
        },
        getData() {
            let file=JSON.parse(JSON.stringify(this.file));
            return file&&file.length?file:[];
        },
        change() {
            let dom = this.$refs['file'];
            let formData = new FormData();
            if (!dom.files||!dom.files[0]) return;
            formData.append('file', dom.files[0]);
            let extension = dom.files[0].name.split('.').pop().toLowerCase();
            if (this.accept.split(',').indexOf('.' + extension) === -1) {
                this.$message.warning('不支持 ' + extension.toLowerCase() + ' 文件');
                return;
            }
            dom.value = '';
            this.$bbs.post(this.action, formData,{
                need:{
                    error:true,
                }
            }).then(data => {
                if (data.code !== 200) {
                    this.$message.warning(data.message);
                    return;
                }
                this.file.push({title: data.data.title || data.data.name, url: data.data.url||data.data.src});
            });
        },
        upload() {
            this.$refs['file'].click();
        }
    }
}
</script>

<style scoped lang="less">
.file-control {
    display: flex;

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        text-align: right;
        flex-basis: 130px;
        flex-shrink: 0;
    }

    .file-list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        .item {
            &.thumb{
                width: 100px;
                height: 100px;
                margin: 0 15px 15px 0;
                .img-span{
                    display: flex;
                }
            }

            .title-text{
                padding: 4px;
                border-radius: 0;
                display: inline-block;
            }
            margin-right: 10px;
            cursor: pointer;
            //padding: 5px 20px 5px 5px;
            border-radius: 2px;
            color: #007bff;
            text-decoration: underline;
            position: relative;
            border: 1px solid #51a4fe;

            .img-span {
                display: none;
                align-items: center;
                height: 100%;
                justify-content: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .remove {
                color: red;
                position: absolute;
                top: -15px;
                right: 1px;
            }
        }

        .upload {
            display: inline-block;
            padding: 10px;
        }
    }

}

</style>
