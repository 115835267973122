import Vue from 'vue'
import router from "@/router";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
// import 'reset-css';
import '@/assets/style/base.css';
import App from '@/app';
import store from "@/vuex/index";
import axios from '@/utils/axios';
import Event from "@/utils/event";


import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
import hevueImgPreview from 'hevue-img-preview'

Vue.use(hevueImgPreview, {
    keyboard: true,
    clickMaskCLose: true
});
// import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
//

// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);
import IconFont from "@/components/common/iconfont";

Vue.component('icon-font', IconFont);
import formDialog from "@/components/common/form";

Vue.component('FormDialog', formDialog);
import MyTable from '@/components/common/my-table';

Vue.component('MyTable', MyTable);

import VueProgressBar from 'vue-progressbar';
// Vue.prototype.con=function(){
//     for(let i=0;i<arguments.length;i++){
//         if (arguments[i]){
//             console.log(JSON.parse(JSON.stringify(arguments[i])));
//         }else{
//             console.log(arguments[i]);
//         }
//     }
// };
import tools from "@/utils/tools";

Vue.prototype.tools = tools;
Vue.prototype.$tools = tools;
Vue.prototype.$event = new Event();
Vue.config.devtools = false;
Vue.directive('access', {
    bind: function (el, bind) {
        el.style.display = tools.checkPermission(bind.value) ? '' : 'none';
    }
});

//这个指令主要用来判断是不是自己
Vue.directive('self', {
    update: function (el, bind) {
        if (bind.value&&bind.value.d){
            //相当于调试
            console.log(bind.value);
        }
        if (store.state.userData&&store.state.userData.group_id=='1'){
            el.style.display='';
        }else{
            el.style.display = bind.value && bind.value.username === store.state.userData.username ? '' : 'none';
        }
    }
});

Vue.use(VueProgressBar, {
    color: '#38C172',
    failedColor: '#87071e',
    thickness: '3px',
});

Vue.prototype.$axios = axios(process.env.VUE_APP_MAIL_HOST);
Vue.prototype.$axiosUpload = axios(process.env.VUE_APP_UPLOAD_HOST);
Vue.prototype.$bbs = axios('/', {
    headers: {
        // bigecodetest: process.env.NODE_ENV === 'development' ? '123456qwer' : "",
        bigecodetest:  '123456qwer',
        bigemaptest: '1'
    }
});

Vue.prototype.$axiosCn = axios(process.env.VUE_APP_CN_HOST);

Vue.prototype.$com = axios(process.env.VUE_APP_COM_HOST);
Vue.prototype.$axiosServer = axios(process.env.VUE_APP_HISTORY_HOST);
Vue.config.productionTip = process.env.NODE_ENV === 'development';
Vue.use(ElementUI);
export default new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
