function token(token = null) {
    if (token === null) {
        return storage(process.env.VUE_APP_TOKEN);
    } else {
        return storage(process.env.VUE_APP_TOKEN, token);
    }
}

function permission(p = null) {
    if (p === null) {
        return storage(process.env.VUE_APP_PERMISSION);
    } else {
        return storage(process.env.VUE_APP_PERMISSION, p);
    }
}

function userData(data = null) {
    if (data === null) {
        return storage(process.env.VUE_APP_USERDATA);
    } else {
        return storage(process.env.VUE_APP_USERDATA, data);
    }
}

function clear() {
    localStorage.removeItem(process.env.VUE_APP_STORAGE);
}

function storage(key, value = null) {
    //取出系统的数据
    try {
        let data = localStorage.getItem(process.env.VUE_APP_STORAGE);
        if (!data) data = '{}';
        data = JSON.parse(data);
        if (value === null) {
            return data[key];
        } else {
            data[key] = value;
        }
        return localStorage.setItem(process.env.VUE_APP_STORAGE, JSON.stringify(data));
    } catch (e) {
        return false;
    }
}

//这个方法主要用来保存通知的相关数据
function elseStorage(data) {
    //取出系统的数据
    try {
        if (data) {
            return localStorage.setItem(process.env.VUE_APP_ELSE_CACHE, JSON.stringify(data));
        }
        data = localStorage.getItem(process.env.VUE_APP_ELSE_CACHE);
        if (!data)  return  {};
        data = JSON.parse(data);
        return data;
    } catch (e) {
        console.log(e);
        return {};
    }
}

export default {
    token, storage, elseStorage, permission, userData, clear
}
