export default class Event {
    listeners = {};

    constructor() {

    }

    on(type, event) {
        document.addEventListener(type,event);
    }

    emit(type, data) {
        let event=new CustomEvent(type,{detail:data});
        document.dispatchEvent(event);
    }

    off(type,event){
        if (type&&event){
            document.removeEventListener(type,event);
        }
    }
}
