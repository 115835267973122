<template>
    <span>
        {{ timeStr }}
    </span>
</template>

<script>
export default {
    props: {
        readable: {
            default: 0
        },
        time: {
            require: true,
            type: Number,
        },
        format: {
            default: function () {
                return 'Y-m-d H:i:s';
            }
        }
    },
    name: "format-time",
    computed: {
        timeStr: function () {
            if (this.readable) {
                let reaable = this.getReadable();
                if (reaable) return reaable;
            }
            try {
                if (!this.time) return '';
                let t = this.time.toString().length === 10 ? this.time * 1000 : this.time;
                t = new Date(t);
                let f = {
                    Y: t.getFullYear(),
                    m: (t.getMonth() + 1).toString().padStart(2, '0'),
                    d: t.getDate().toString().padStart(2, '0'),
                    H: t.getHours().toString().padStart(2, '0'),
                    i: t.getMinutes().toString().padStart(2, '0'),
                    s: t.getSeconds().toString().padStart(2, '0')
                }
                let result = this.format;
                for (let k in f) {
                    result = result.replace(k, f[k]);
                }
                return result;
            } catch (e) {
                console.log(e);
                return '';
            }

        }
    },
    methods: {
        getReadable() {
            if (!this.time) return '';
            let t = parseInt(this.time);
            if (isNaN(t)) return '';
            t = t.toString().length === 10 ? t * 1000 : t;
            switch (true) {
                case new Date().getTime() - t < 1000 * 60:
                    return '刚刚';
                    break;
                case new Date().getTime() - t < 1000 * 60 * 60:
                    return Math.floor((new Date().getTime() - t) / 1000 / 60) + '分钟前';
                    break;
                case new Date().getTime() - t < 1000 * 60 * 60 * 12:
                    return Math.floor((new Date().getTime() - t) / 1000 / 3600) + '小时前';
                    break;

            }
        }
    }
}
</script>

<style scoped>

</style>
