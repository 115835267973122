import Vue from 'vue';
import Vuex from 'vuex';
import storage from "@/utils/storage";
import dict from './module/dict';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        loading: false,
        loginDialog: false,//展示登陆的提示
        permission: [],
        userData: {},
        need_login: false,
        //决定是不是要展示添加问题的窗口
        question: {
            show: '',//用来判断是不是要展示那个弹窗
            title: '22',//用来显示标题
            data: {}
        },
        //因为多个地方，需要使用到关联的问题，所以使用这个保存相关联的问题，方便展示
        connect: [],
        //在修改了问答的内容之后，会修改这个值，然后相关的页面监听edit 的值，从而实现更新内容
        editData: {
            edit: '',
            data: {}
        },
        //通知的总数
        notice: {
            answer: [],
            comment: [],
            reply: [],
            notice: []
        },
        answer: {
            username: {}
        },
    },

    modules: {
        dict
    },
    mutations: {
        updateLoading(state, loading) {
            state.loading = loading;
        },
        updateForm(state, fromData) {
            state.form = fromData;
        },
        updatePermission(state, permission) {
            state.permission = permission;
            storage.permission(permission);
        },
        setDict(state, data) {
            state.dict = Object.assign({}, state.dict, data);
        },
        updateNotice(state, data) {
            state.notice = data;
        },
        //由于审核是在新的页面审核的，为了多个页面可以将审核的通知删除，这里写了这个方法
        handleNotice(state, data) {
            // debugger;
            let typeKey = '';
            switch (data.type) {
                case 'answer':
                    typeKey = 'answer_id';
                    break;
                case 'comment':
                    typeKey = 'comment_id';
                    break;
                case 'reply':
                    typeKey = 'reply_id';
                    break;
                case 'notice':
                    typeKey = 'notice_id';
                    break;
            }
            if (!typeKey) return;
            let typeData = state.notice[data.type];
            if (!typeData) return;
            typeData = JSON.parse(JSON.stringify(typeData));
            let index = typeData.findIndex(v => v[typeKey] == data.id);
            if (index > -1) typeData.splice(index, 1);
            let elseData = storage.elseStorage();
            if (typeof elseData !== 'object') elseData = {};
            if (!elseData[data.type]) elseData[data.type] = {};
            elseData[data.type][data.id] = {time: new Date().getTime(), id: data.id};
            storage.elseStorage(elseData);
            state.notice[data.type] = typeData;
        },
        updateDict(state, data) {
            state.dict = Object.assign({}, state.dict, data);
            // console.log(state,data);
        },
        updateUserData(state, userData) {
            // if (process.env.NODE_ENV==='development'){
            //     if (userData.face&&userData.face.indexOf(process.env.VUE_APP_HOST)===-1) userData.face=process.env.VUE_APP_HOST+userData.face;
            // }
            state.userData = Object.assign({}, state.userData, userData);
            //保存起来，下次刷新的时候可以拿到
            storage.userData(userData);
        },
        clearUserData(state) {
            state.userData = {};
        },
        needLogin(state, login) {
            state.need_login = login;
        },
        editAnswer(state, question) {
            state.editData = question;
        },
        updateCurrentQuestion(state, question) {
            state.question = Object.assign({}, question);
        },
        updateConnect(state, connect) {
            state.connect = connect;
        },
        setCurrentAnswer(state, answer) {
            state.answer = answer;
        }
    },

    actions: {
        loading(context, status) {
            context.commit('updateLoading', status);
        },
        setDict(context, data) {
            context.commit('setDict', data);
        },
        openForm(context, data) {
            context.commit('updateForm', data);
        },
        //登陆的时候设置权限
        permission(context, permission) {
            context.commit('updatePermission', permission);
        },
        userData(context, userData) {
            context.commit('updateUserData', userData);
        }
    }
});
