<template>
    <el-dialog :destroy-on-close="true" :title="title" @opened="opened" v-if="isShow" :visible.sync="isShow"
               :show-close="false" :close-on-press-escape="false"
               :close-on-click-modal="false">
        <el-form :model="data" :rules="rules" @keyup.native.enter="submit" @submit.native.prevent="submit"
                 ref="formDialog" class="form" label-position="right" label-width="130px">
            <template v-for="item in fields">
                <el-input v-model="data[item.name]" :key="item.name"
                          v-if="['hidden','primary'].indexOf(item.type)>-1" type="hidden" autocomplete="off"></el-input>
                <el-form-item :key="item.name" :prop="item.name" :label="item.title"
                              v-else-if="['select','tag'].indexOf(item.type)>-1">
                    <el-select :disabled="item.disabled" v-model="data[item.name]">
                        <el-option
                            v-for="option in item.options"
                            :key="option.key"
                            :label="option.label"
                            :value="option.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :key="item.name" :prop="item.name" :label="item.title"
                              v-else-if="['dic'].indexOf(item.type)>-1">
                    <el-select :disabled="item.disabled" v-model="data[item.name]" :placeholder="item.placeholder">
                        <el-option
                            v-for="option in item.options"
                            :key="option.key"
                            :label="option.label"
                            :value="option.key"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :key="item.name" :prop="item.name" :label="item.title"
                              v-else-if="['dict_select','dict_key'].indexOf(item.type)>-1">
                    <el-select :disabled="item.disabled" v-model="data[item.name]" :placeholder="item.placeholder">
                        <el-option
                            v-for="v in $store.state.dict[item.dict_key]"
                            :key="v.value"
                            :label="v.label"
                            :value="v.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :key="item.name" :prop="item.name" :label="item.title"
                              v-else-if="['dict_tag'].indexOf(item.type)>-1">
                    <el-select :disabled="item.disabled" v-model="data[item.name]" :placeholder="item.placeholder">
                        <el-option
                            v-for="v in $store.state.dict[item.dict_key]"
                            :key="v.value"
                            :label="v.label"
                            :value="v.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-else-if="['password'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-input show-password v-model="data[item.name]" autocomplete="off" :placeholder="item.placeholder"></el-input>
                </el-form-item>
                <div v-else-if="['tree'].indexOf(item.type)>-1" :key="item.name" :label="item.title">
                    <el-tree
                        :ref="item.name"
                        :data="data[item.name].all"
                        show-checkbox
                        :node-key="item.id"
                        :default-expand-all="true"
                        :default-checked-keys="data[item.name].value"
                        :props="item.props">
                    </el-tree>
                </div>
                <el-form-item v-else-if="['checkbox'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-checkbox-group v-model="data[item.name]">
                        <el-checkbox :key="option.value" v-for="option in $store.state.dict[item.dict_key]"
                                     :label="option.value">{{ option.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item v-else-if="['switch'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-switch
                        v-model="data[item.name]"
                        active-color="#13ce66"
                        inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item v-else-if="['date'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-date-picker
                        :disabled="item.disabled"
                        v-model="data[item.name]"
                        value-format="timestamp"
                        type="date">
                    </el-date-picker>
                </el-form-item>
                <div v-else-if="['image_upload','single_image'].indexOf(item.type)>-1" :key="item.name"
                     :title="item.title">
                    <div class="float-left text-right" style="width: 120px;">{{ item.info || item.title }}</div>
                    <div style="padding-left: 130px;">
                        <image-upload :ref="'imageUpload'+item.name" :dir="item.dir||''" :url="item.url"
                                      :limit="item.type==='single_image'?1:0" :name="item.name"
                                      :files="data[item.name]">
                        </image-upload>
                    </div>
                </div>
                <wanedit :ref="'editor_'+item.name" v-else-if="['editor','bbs_editor'].indexOf(item.type)>-1" :config="item.config||{}" :key="item.name"
                         :content="data[item.name]"></wanedit>
                <coder v-else-if="['code'].indexOf(item.type)>-1" :ref="'code_'+item.name" :key="item.name"
                       :content="data[item.name]"></coder>
                <file v-else-if="['file','single_file','img_file'].indexOf(item.type)>-1" :ref="'file_'+item.name" :key="item.name"
                      :file="data[item.name]" :accept="item.accept" :limit="item.limit" :action="item.action"
                      :title="item.title"></file>
                <el-form-item v-else-if="['cascader'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-cascader v-model="data[item.name]" :options="item.options"></el-cascader>
                </el-form-item>

                <el-form-item v-else-if="['textarea'].indexOf(item.type)>-1" :key="item.name" :label="item.title"
                              :prop="item.name">
                    <el-input v-model="data[item.name]" :placeholder="item.placeholder" type="textarea"></el-input>
                </el-form-item>
                <el-form-item v-else :key="item.name" :label="item.title" :prop="item.name">
                    <el-input v-model="data[item.name]" :placeholder="item.placeholder" :disabled="item.disabled"
                              type="text"
                              autocomplete="off"></el-input>
                </el-form-item>
            </template>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelDialog">取 消</el-button>
            <el-button type="primary" :loading="$store.state.loading" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import ImageUpload from './image-upload';
import File from './file';
import Wanedit from "@/components/common/wanedit";
import Coder from '@/components/common/code';

export default {
    name: "formDialog",
    components: {
        ImageUpload, File, Wanedit, Coder
    },
    props: {
        fields: {
            require: true,
        },
        data: {
            type: Object,
            require: true,
        },
        show: {
            type: Boolean,
            require: true,
        },
        rules: {
            type: Object,
        },
        title: {
            type: String,
            default: '详情',
        }
    },
    methods: {
        opened() {

        },
        cancelDialog() {
            this.$emit('closeFormDialog', {type: 'cancel'});
        },
        submit(e) {
            if (e.target && (e.target.tagName === 'TEXTAREA'||e.target.tagName==='DIV')) {
                //如果是textarea 不执行回车事件
                return;
            }
            if (this.isSubmit) return;
            this.isSubmit = true;
            setTimeout(() => {
                this.isSubmit = false;
            }, 500);
            this.$refs['formDialog'].validate((valid) => {
                if (!valid) return false;
                let editData = {};
                this.fields.map(v => {
                    switch (v.type) {
                        case 'switch':
                            editData[v.name] = this.data[v.name] ? 1 : 0;
                            break;
                        case 'tree':
                            editData[v.name] = [...this.$refs[v.name][0].getCheckedKeys(), ...this.$refs[v.name][0].getHalfCheckedKeys()];
                            break;
                        case 'date':
                            editData[v.name] = this.data[v.name] / 1000;
                            break;
                        case 'code':
                            if (this.$refs['code_' + v.name] && this.$refs['code_' + v.name].length) {

                                editData[v.name] = this.$refs['code_' + v.name][0].getCode();
                            } else {
                                editData[v.name] = '';
                            }
                            break;
                        case 'single_file':
                        case 'file':
                        case 'img_file':
                            if (this.$refs['file_' + v.name] && this.$refs['file_' + v.name].length) {
                                let file = this.$refs['file_' + v.name][0].getData();
                                if (file && file.length) {
                                    if (v.type === 'single_file') {
                                        editData[v.name] = file[0]['url'] || file[0]['href'] || file[0]['src'];
                                    } else {
                                        editData[v.name] = file;
                                    }
                                } else {
                                    editData[v.name] = '';
                                }
                            } else {
                                editData[v.name] = '';
                            }
                            break;
                        case 'editor':
                        case 'bbs_editor':
                            if (this.$refs['editor_'+v.name]&&this.$refs['editor_'+v.name][0]){
                                editData[v.name]= this.$refs['editor_'+v.name][0].getHtml();
                            }
                            break;
                        case 'image_upload':
                        case 'single_image':
                            if (this.$store.state.dict.image[v.name] && this.$store.state.dict.image[v.name].length) {
                                editData[v.name] = this.$store.state.dict.image[v.name].map(vv => {
                                    vv.url = vv.url.replace(v.baseUrl, '');
                                    return vv;
                                });
                            } else {
                                editData[v.name] = [];
                            }
                            break;
                        default:
                            if (typeof this.data[v.name] !== 'undefined') {
                                editData[v.name] = this.data[v.name];
                            }
                    }
                });
                this.$emit('closeFormDialog', {type: 'submit', data: editData});
            });
        },
    },
    data() {
        return {
            isSubmit: false,
            isShow: this.show,
        }
    },
    watch: {
        show: function (val) {
            this.isShow = val;
        }
    }
}
</script>
<style scoped>
.form ::v-deep input {
    height: 35px;
    line-height: 35px;
}
</style>
