<template>
    <div>
        <el-table
            :row-key="rowKey"
            :data="data"
            :stripe="stripe"
            :border="border"
            :size="size"
            @sort-change="sortChange"
            :default-sort="sort"
            :row-class-name="rowClassName"
            style="width: 100%">
            <el-table-column v-for="item in fields"
                             :key="item.name"
                             :prop="item.name"
                             :fixed="item.fixed"
                             :sortable="item.sortable"
                             :label="item.title"
                             :min-width="item.minWidth"
                             :width="item.width">
                <template slot-scope="scope">
                        <span v-if="['dict_select','radio','dict_key'].indexOf(item.type)>-1">
                            {{ reStoreKeyValue(item.dict_key, scope.row[item.name]) }}
                        </span>
                    <span v-else-if="['checkbox'].indexOf(item.type)>-1">
                            {{ reStoreCheckBoxValue(item.dict_key, scope.row[item.name]) }}
                        </span>
                    <el-tag v-else-if="['dict_tag'].indexOf(item.type)>-1"
                            :type="reStoreTagType(item.dict_key,scope.row[item.name])">
                        {{ reStoreKeyValue(item.dict_key, scope.row[item.name]) }}
                    </el-tag>
                    <span v-else-if="['date','month','daterange'].indexOf(item.type)>-1">
                        <format-time :time="scope.row[item.name]" :format="item.format"></format-time>
                    </span>
                    <span v-else-if="['img_file'].indexOf(item.type)>-1">
                        <image-preview  :images="scope.row[item.name]"></image-preview>
                    </span>
                    <span v-else-if="['age'].indexOf(item.type)>-1">
                        <age-calculate :id="scope.row[item.valueKey]"></age-calculate>
                    </span>
                    <span v-else>
                            {{ scope.row[item.name] }}
                    </span>
                </template>
            </el-table-column>
            <slot name="operation">
                
            </slot>
        </el-table>
    </div>
</template>

<script>
import FormatTime from './format-time';
import ImagePreview from "@/components/common/image-preview";

export default {
    name: "myTable",
    components: {
        FormatTime,ImagePreview
    },
    props: {
        data: {
            require: true,
            type: Array,
        },
        rowKey: {},
        fields: {
            require: true,
            type: Array,
        },
        stripe: {
            default: function () {
                return true;
            }
        },
        sortChange: {
            type: Function,
            default: function () {
                return () => {
                };
            }
        },
        rowClassName: {
            type: Function,
            default: function () {
                return () => {
                };
            }
        },
        size: {},
        border: {
            default: function () {
                return true;
            }
        },
        sort: {}
    },
    methods: {
        reStoreTagType(key, value) {
            let type = '';
            if (this.$store.state.dict[key]) {
                this.$store.state.dict[key].map(v => {
                    if (v.value === value) type = v.type;
                });
            }
            return type;
        },
        reStoreCheckBoxValue(key, value) {
            let readableValue=[];
            value.map(v=>{
                let has=false;
                if (this.$store.state.dict[key]) {
                    this.$store.state.dict[key].map(vv => {
                        if (vv.value === v) {
                            has=true;
                            readableValue.push(vv.label);
                        }
                    });
                }
                if (!has) readableValue.push(v);
            });
            return readableValue.join(',');
            // console.log(readableValue);

        },
        reStoreKeyValue(key, value) {
            let readableValue = value;
            if (this.$store.state.dict[key]) {
                this.$store.state.dict[key].map(v => {
                    if (v.value === value) readableValue = v.label;
                });
            }
            // console.log(readableValue);
            return readableValue;
        }
    }
}
</script>

<style scoped>

</style>
