<template>
    <div>
        <el-upload
                ref="elUploader"
                :action="action"
                name="image"
                list-type="picture-card"
                :file-list="files"
                :limit="limit"
                accept="image/jpeg,image/gif,image/png,image/jpg"
                :on-remove="remove"
                :on-success="success"
                :on-exceed="exceed"
                :auto-upload="true">
            <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <el-dialog ref="img-dialog" @opened="opened" :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "image-upload",
        props: {
            files: {
                type: Array,
                require: true,
            },
            dir:{
                default:'',
            },
            url:{
                default:'',
            },
            limit: {
                default: function () {
                    return 0;
                }
            },
            name: {
                require: true,
            }
        },
        data: function () {
            return {
                action: process.env.VUE_APP_MAIL_HOST + '/manage/api/upload/image',
                dialogVisible: false,
                dialogImageUrl: '',
            }
        },
        methods: {
            opened: function () {

            },
            clearFiles:function (){
                this.$refs['elUploader'].clearFiles();
            },
            remove: function (file) {
                let data = JSON.parse(JSON.stringify(this.$store.state.dict.image[this.name]));
                let index = data.findIndex(v => v.url === file.url);
                if (index !== -1) {
                    data.splice(index, 1);
                    this.$store.dispatch('setImage', {[this.name]: data});
                }
            },
            exceed: function () {
                this.$message.warning('只允许上传' + this.limit + '个文件');
            },
            getData(){

            },
            success: function (response, file, fileList) {
                if (response.code !== 200) {
                    let index = fileList.findIndex(v => v.uid === file.uid);
                    if (index !== -1) {
                        fileList.splice(index, 1);
                    }
                    return;
                }
                let data = this.$store.state.dict.image[this.name]?JSON.parse(JSON.stringify(this.$store.state.dict.image[this.name])):[];
                data.push({name:file.name,uid:file.uid,url:response.data.url});
                this.$store.dispatch('setImage', {[this.name]: data});
            }
        }
    }
</script>

<style scoped>

</style>
