<template>
    <div class="my-ace" ref="code" @keyup.stop.enter="()=>{}">
        <div class="top-bar">
        <span @click="toggleFull" class="my-full">
            <i class="el-icon-full-screen"></i>
        </span>
        </div>
        <ace
            ref="editor"
            :value="code"
            @init="ready"
            lang="html"
            theme="chrome"
            :height="h"
            :options="{
                fontSize:14,
                 showPrintMargin: false
            }"
            width="100%">
        </ace>
    </div>
</template>

<script>
import ace from 'vue2-ace-editor';
import 'brace/ext/beautify'
import 'brace/theme/chrome';
// import 'brace/ext/language_tools';
import 'brace/mode/html';
import 'brace/mode/javascript';
import screenfull from 'screenfull';

export default {
    name: "MyCode",
    props: ['content', 'height'],
    components: {
        ace
    },
    data() {
        return {
            code: '',
            oldH: '',
            h: this.height ? this.height : '250px',
        }
    },
    methods: {
        ready() {
            this.code=this.content||'';
        },
        enter() {

        },
        getCode(){
          return this.$refs['editor'].editor.getValue();
        },
        toggleFull() {
            screenfull.toggle(this.$refs.code);
            setTimeout(() => {
                if (screenfull.isFullscreen) {
                    this.h = '100vh';
                } else {
                    this.h = this.height ? this.height : '250px';
                }
            }, 400);
        }
    }
}
</script>

<style scoped lang="less">
.my-ace {
    border-bottom: 2px solid #eee;

    .top-bar {
        position: relative;
        padding: 10px 0;
        background-color: #eee;

        .my-full {
            cursor: pointer;
            padding: 10px;
            position: absolute;
            z-index: 9;
            top: -7px;
            right: -7px;
        }
    }
}
</style>